<template>
  <div class="order-details">
    <h4 class="order-details__title">Детали заказа</h4>
    <EnteringPromoCodeComponent />
    <template v-if="userPoints && !activePromoCode">
      <div class="order-details__points">
        <span>Доступно: {{ userPoints | plural("балл", "балла", "баллов") }}</span>
        <label class="order-details__checkbox">
          <CheckboxComponent :checked="usePoints" @change="setUsePoints" />
          <span class="order-details__checkbox-title"
            >Списать {{ amountWriteOffPoints | plural("балл", "балла", "баллов") }}</span
          >
        </label>
      </div>
    </template>
    <div class="order-details__prices">
      <div v-if="promoCodeSaleValue || usePoints" class="order-details__prices-item">
        <span>Сумма без скидок</span>
        <span> {{ totalPriceCart | formatPrice }} </span>
      </div>
      <div v-if="usePoints && !activePromoCode" class="order-details__prices-item">
        <span>Бонусы</span>
        <span class="order-details__prices-item--red">-{{ amountWriteOffPoints }} ₽</span>
      </div>
      <div v-if="promoCodeSaleValue" class="order-details__prices-item">
        <span>Промокод</span>
        <span class="order-details__prices-item--red"> -{{ promoCodeSaleValue | formatPrice }} </span>
      </div>
      <!--      <div class="order-details__prices-item">-->
      <!--        <span>Сборка</span>-->
      <!--        <span>0 ₽</span>-->
      <!--      </div>-->
      <div class="order-details__prices-item--total">
        <span>Итого</span>
        <LoadingIndicator v-if="cartLoading" title="" />
        <span v-else>
          {{ cartPriceValue | formatPrice }}
        </span>
      </div>
    </div>
    <div class="order-details__bottom">
      <template v-if="!usePoints && !activePromoCode">
        <div class="order-details__bonuses">
          <span class="order-details__bonuses-title">Вернется бонусами</span>
          <span class="order-details__bonuses-number">+ {{ Math.ceil(numberOfPointsReturned) }}</span>
        </div>
      </template>
      <button
        @click="$emit('submit')"
        :disabled="minOrderPrice > totalPriceCart"
        class="btn btn--lg btn--main order-details__btn"
      >
        Оплатить
      </button>
      <span v-if="minOrderPrice > totalPriceCart" class="order-details__offer order-details__offer--red">
        Не достигнута минимальная сумма заказа для доставки
      </span>
      <span class="order-details__offer">
        Нажимая на кнопку, вы соглашаетесь с условиями использования и публичной офертой
      </span>
    </div>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import LoadingIndicator from "components/Loadingindicator.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import EnteringPromoCodeComponent from "components/EnteringPromoCodeComponent.vue";

export default {
  name: "OrderDetailsComponent",
  components: {
    EnteringPromoCodeComponent,
    RemixIconComponent,
    LoadingIndicator,
    IconComponent,
    InputComponent,
    CheckboxComponent,
  },
  data() {
    return {
      checkbox: false,
      promocode: {
        value: "",
        errors: [],
      },
      percent: null,
    };
  },

  methods: {
    setUsePoints($event) {
      this.$store.state.order_page.use_points = $event;
    },
  },

  computed: {
    cartItems() {
      return this.$store.state.auth.cart;
    },
    totalProductPriceCart() {
      return this.$store.getters.total_product_price_cart(this.cartItems);
    },
    totalSetPriceCart() {
      return this.$store.getters.total_set_price_cart(this.cartItems);
    },
    totalPriceCart() {
      return this.totalProductPriceCart + this.totalSetPriceCart;
    },
    token() {
      return this.$store.state.auth_token;
    },
    totalPriceWithoutSale() {
      return (
        this.$store.getters.total_product_price_cart(this.cartItems, true) +
        this.$store.getters.total_set_price_cart(this.cartItems, true)
      );
    },
    tenPercentOfTheAmount() {
      return Math.ceil(this.totalPriceWithoutSale / 10);
    },
    amountWriteOffPoints() {
      return this.userPoints > this.tenPercentOfTheAmount ? this.tenPercentOfTheAmount : this.userPoints;
    },
    numberOfPointsReturned() {
      return (this.totalPriceWithoutSale * 3) / 100;
    },
    userPoints() {
      return this.$store.state.auth.points?.points;
    },
    minOrderPrice() {
      return this.$store.state.auth.client_delivery_info &&
        this.$store.state.auth.client_delivery_info.selected_address
        ? this.$store.state.auth.client_delivery_info.selected_address.shop_zone.min_order_price
        : null;
    },
    activePromoCode() {
      return this.$store.state.activePromoCode;
    },
    usePoints() {
      return this.$store.state.order_page.use_points;
    },
    cartLoading() {
      return this.$store.state.cart_loading;
    },
    promoCodeSaleValue() {
      let percent = this.$store.state.order_page.promo_code_percent / 100;
      let totalPromoCodeSaleValue = null;

      let productsInCart = this.cartItems.filter((item) => !item.product_set);
      let productsSetInCart = this.cartItems
        .filter((item) => item.product_set)
        .flatMap((set) => set.set_items);
      let allProductsInCart = [...productsInCart, ...productsSetInCart];

      allProductsInCart.forEach((item) => {
        if (this.$store.state.promo_products.includes(item.product.id)) {
          totalPromoCodeSaleValue += item.product.price.value * item.quantity * percent;
        }
      });
      return totalPromoCodeSaleValue;
    },
    cartPriceValue() {
      if (this.usePoints) {
        return this.totalPriceCart - this.amountWriteOffPoints;
      } else if (this.promoCodeSaleValue) {
        return this.totalPriceCart - this.promoCodeSaleValue;
      } else return this.totalPriceCart;
    },
  },
};
</script>

<style lang="stylus">
.order-details {
  max-width 312px
  border 1px solid var(--border-color-2)
  border-radius 16px
  padding 16px
  +above(1025px) {
    position sticky
    top 100px
  }
  +below(1024px) {
    max-width: 100%;
  }

  &__title {
    margin-bottom 16px
    font-size 1.25em
    font-weight 500
    line-height 24px
  }

  &__promocode {
    display flex
    align-items flex-start
    gap 8px
    margin-bottom 24px
  }

  &__promocode-btn {
    border-radius 16px
    width 46px
    height 46px

    &--active {
      background var(--main)
      .icon svg path {
        fill var(--white)
      }
    }
  }

  &__checkbox {
    display flex
    align-items center
    gap 4px
    margin-bottom 24px
    cursor pointer
    width fit-content
  }

  &__checkbox-title {
    //font-size 0.875em
    line-height 20px
    color var(--gray-900)
  }

  &__prices {
    padding 16px 0
    display flex
    flex-direction column
    margin-bottom 16px
    gap 8px
    border-top 1px solid var(--border-color-2)
    border-bottom: 1px solid var(--border-color-2)
  }

  &__prices-item {
    display flex
    justify-content space-between
    font-size 0.875em
    line-height 20px
    color var(--body_muted)

    &--red {
      color: var(--theme-danger, #C61111);
    }

    &--total {
      display flex
      justify-content space-between
      font-size 1.25em
      font-weight 500
      line-height 120%
      color var(--background-color)
    }
  }

  &__bottom {
    display flex
    flex-direction column
    gap 16px
  }

  &__bonuses {
    display flex
    justify-content space-between
  }

  &__bonuses-title {
    font-size 0.875em
    line-height 20px
    color var(--gray-900)
  }

  &__bonuses-number {
    font-size 0.875em
    line-height 20px
    color var(--green)
  }

  &__btn {
    width 100%
    height 50px
    border-radius 16px
  }

  &__offer {
    font-size 0.75em
    line-height 120%
    text-align center
    color var(--body_muted)

    &--red {
      color var(--red)
    }
  }

  &__points {
    display flex
    flex-direction column
    gap 8px
    font-size 0.766rem
    line-height 20px
    color var(--gray-900)
  }
}
</style>
